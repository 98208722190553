import React from 'react';
import BaseLayout from '../../components/templates/BaseLayout';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Button from '../../components/atoms/inputs/Button';
import HorizontalSlider from '../../components/molecules/HorizontalSlider';
import SEO from '../../components/atoms/utility/seo';
import BusinessApplicant from '../../components/organisms/BusinessApplicant';

const BusinessPage = ({ data }) => {
  const {
    aiIconImg,
    artPrizeUsecaseImg,
    basicBeLogoImg,
    brickyardLogoImg,
    childrensMuseumLogoImg,
    cityFlatsHotelLogoImg,
    feetIconImg,
    flockxTouchImg,
    graamaLogoImg,
    launchIconImg,
    linkIconImg,
    neighborhoodUsecaseImg,
    rebelLogoImg,
    smBusinessImg,
    timeResourcesIconImg,
    unlockThePowerImg,
    captivateImage,
    integrateImage,
    aboardDesktopImage,
  } = data;

  const handleDemoSignUp = () => {
    if (typeof window !== 'undefined') {
      const targetElement = document.getElementById(
        'business-applicant-sign-up-form'
      );
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <BaseLayout>
      <SEO
        title="The Future of Business Marketing | Join the Flockx Ecosystem"
        keywords={[
          'small business empowerment',
          'enterprise marketing',
          'local digital marketing',
          'business solutions',
          'product showcase',
          'community growth',
          'innovative technology',
          'success unlock',
          'customer retention',
        ]}
        description={
          'Automate marketing for your Activities, Happy Hours, and Specials with our new AI ecosystem. Drive more customers to your events and specials with hyper targeted and affordable advertising.'
        }
      />
      <div className="mx-auto">
        {/* Hero Section */}
        <section className="text-center p-2 py-12">
          <h1 className="font-bold text-4xl lg:text-7xl mt-12 px-4 mb-4 !leading-[normal] text-grey">
            Empower your Activities with <br></br>
            <span className="text-highlight">AI-Driven Marketing</span>
          </h1>
          <p className="max-w-[300px] mx-auto sm:max-w-none mb-6 lg:mb-10 text-2xl lg:text-3xl">
            Revolutionize your Activities & Events
          </p>
          <Button
            label={'Get Started Today'}
            className="min-w-[200px] mb-8"
            onClickHandler={handleDemoSignUp}
          ></Button>
          {/* <p className="mb-12 text-base lg:text-2xl">
            Currently serving Grand Rapids.
          </p> */}
          <h2 className="text-2xl lg:text-3xl mb-12 lg:mb-12 !leading-[normal] text-grey">
            We're Trusted by <br />{' '}
            <span className="text-gray-500">
              Leading Brands and Local Businesses
            </span>
          </h2>
          <div className="flex justify-between items-center space-x-4 max-w-5xl mx-auto gap-12">
            <div className="mb-4 lg:mb-0 hidden md:block">
              <GatsbyImage
                image={getImage(brickyardLogoImg)}
                alt="Brickyard Tavern Logo"
                className="h-full"
              />
            </div>
            <div className="mb-4 lg:mb-0">
              <GatsbyImage
                image={getImage(childrensMuseumLogoImg)}
                alt="Children's Museum Logo"
                className="h-full"
              />
            </div>
            <div className="mb-4 lg:mb-0">
              <GatsbyImage
                image={getImage(graamaLogoImg)}
                alt="Graama Logo"
                className="h-full"
              />
            </div>
            <div className="mb-4 lg:mb-0 hidden lg:block">
              <GatsbyImage
                image={getImage(cityFlatsHotelLogoImg)}
                alt="City Flat Logo"
                className="h-full"
              />
            </div>
            <div className="mb-4 lg:mb-0">
              <GatsbyImage
                image={getImage(rebelLogoImg)}
                alt="Rebel Logo"
                className="h-full"
              />
            </div>
          </div>
        </section>
        {/* Features Section */}
        <section className="bg-highlight py-12 p-6 text-white -mx-4 md:-mx-6 lg:-mx-0">
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-8 !leading-[normal]">
            Experience a new way of hosting
            <br /> Activities with AI-Community Marketing
          </h2>
          <HorizontalSlider className="block lg:hidden" whiteDots={true}>
            <div className="w-full px-2 mb-4 md:w-1/2 lg:w-1/3">
              <div className="min-h-[300px] p-4 lg:p-6 bg-white text-grey rounded-xl shadow-lg">
                <GatsbyImage
                  image={getImage(aiIconImg)}
                  alt="AI Generated Marketing Assets"
                  className="w-12 h-12 mb-4"
                />
                <h3 className="font-bold text-xl mb-4">
                  AI generated <br /> marketing assets
                </h3>
                <p>
                  Harness the Power of AI to curate bespoke design assets for
                  Digital Marketing from your hosted Activities and Events!
                </p>
              </div>
            </div>
            <div className="w-full px-2 mb-4 md:w-1/2 lg:w-1/3">
              <div className="min-h-[300px] p-4 lg:p-6 bg-white text-grey rounded-xl shadow-lg">
                <GatsbyImage
                  image={getImage(timeResourcesIconImg)}
                  alt="Save Time and Resources"
                  className="w-12 h-12 mb-4"
                />
                <h3 className="font-bold text-xl mb-4">
                  Save time and
                  <br /> resources
                </h3>
                <p>
                  Streamline your marketing efforts with AI efficiency. Our
                  platform automates asset creation, freeing up your time and
                  resources for strategic growth.
                </p>
              </div>
            </div>
            <div className="w-full px-2 mb-4 md:w-1/2 lg:w-1/3">
              <div className="min-h-[300px] p-4 lg:p-6 bg-white text-grey rounded-xl shadow-lg">
                <GatsbyImage
                  image={getImage(feetIconImg)}
                  alt="Drive Foot Traffic and Engagement"
                  className="w-12 h-12 mb-4"
                />
                <h3 className="font-bold text-xl mb-4">
                  Drive foot traffic <br />
                  and engagement
                </h3>
                <p>
                  Use a Community Network to release impactful advertisements,
                  driving higher foot traffic and engagement to your Activities.
                </p>
              </div>
            </div>
          </HorizontalSlider>
          <div className="hidden lg:flex flex-col lg:flex-row -mx-2 xl:-mx-0 xl:gap-4">
            <div className="w-full px-2 mb-4 md:w-1/2 lg:w-1/3">
              <div className="min-h-[300px] p-4 lg:p-6 bg-white text-grey rounded-xl shadow-lg">
                <GatsbyImage
                  image={getImage(aiIconImg)}
                  alt="AI Generated Marketing Assets"
                  className="w-12 h-12 mb-4"
                />
                <h3 className="font-bold text-xl mb-4">
                  AI generated <br /> marketing assets
                </h3>
                <p>
                  Harness the Power of AI to curate bespoke design assets for
                  Digital Marketing from your hosted Activities and Events!
                </p>
              </div>
            </div>
            <div className="w-full px-2 mb-4 md:w-1/2 lg:w-1/3">
              <div className="min-h-[300px] p-4 lg:p-6 bg-white text-grey rounded-xl shadow-lg">
                <GatsbyImage
                  image={getImage(timeResourcesIconImg)}
                  alt="Save Time and Resources"
                  className="w-12 h-12 mb-4"
                />
                <h3 className="font-bold text-xl mb-4">
                  Save time and
                  <br /> resources
                </h3>
                <p>
                  Streamline your marketing efforts with AI efficiency. Our
                  platform automates asset creation, freeing up your time and
                  resources for strategic growth.
                </p>
              </div>
            </div>
            <div className="w-full px-2 mb-4 md:w-1/2 lg:w-1/3">
              <div className="min-h-[300px] p-4 lg:p-6 bg-white text-grey rounded-xl shadow-lg">
                <GatsbyImage
                  image={getImage(feetIconImg)}
                  alt="Drive Foot Traffic and Engagement"
                  className="w-12 h-12 mb-4"
                />
                <h3 className="font-bold text-xl mb-4">
                  Drive foot traffic <br />
                  and engagement
                </h3>
                <p>
                  Use a Community Network to release impactful advertisements,
                  driving higher foot traffic and engagement to your Activities.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* Unlock Section */}
        <section className="p-6 py-14">
          <h2 className="text-3xl font-bold text-center mb-8 lg:mb-12 text-grey">
            Unlock the Power Inside your Events
          </h2>

          <div className="flex flex-wrap justify-between items-center">
            <div className="w-full lg:w-1/2">
              <ul className="list-none space-y-6 lg:space-y-8 mx-auto max-w-lg lg:max-w-none">
                <li className="flex items-center">
                  <div className="flex items-center justify-center h-full">
                    <GatsbyImage
                      image={getImage(linkIconImg)}
                      alt="Link Icon"
                      className="w-10 h-10 mr-4 lg:mr-8 rounded-xl"
                    />
                  </div>
                  <div className="w-[75%]">
                    <h3 className="font-bold mb-2 text-grey text-lg">
                      One Link, Infinite Possibilities
                    </h3>
                    <p>
                      Enable Brand identity, Activity & Event detection, Share
                      your URL to get started.
                    </p>
                  </div>
                </li>
                <li className="flex items-center">
                  <div className="flex items-center justify-center h-full">
                    <GatsbyImage
                      image={getImage(aiIconImg)}
                      alt="AI Icon"
                      className="w-10 h-10 mr-4 lg:mr-8 rounded-xl"
                    />
                  </div>
                  <div className="w-[75%]">
                    <h3 className="font-bold mb-2 text-grey text-lg">
                      AI Generated Magic
                    </h3>
                    <p>
                      Generate bespoke Activity assets with minimal effort and
                      preview your promotions before launching.
                    </p>
                  </div>
                </li>
                <li className="flex items-center">
                  <div className="flex items-center justify-center h-full">
                    <GatsbyImage
                      image={getImage(flockxTouchImg)}
                      alt="Authentic Flock Icon"
                      className="w-10 h-10 mr-4 lg:mr-8 rounded-xl"
                    />
                  </div>
                  <div className="w-[75%]">
                    <h3 className="font-bold mb-2 text-grey text-lg">
                      Our Authentic Touch
                    </h3>
                    <p>
                      AI precision, Human touch. Our design team ensures
                      authenticity in every final asset, committing to
                      excellence when published.
                    </p>
                  </div>
                </li>
                <li className="flex items-center">
                  <div className="flex items-center justify-center h-full">
                    <GatsbyImage
                      image={getImage(launchIconImg)}
                      alt="Launch Network Icon"
                      className="w-10 h-10 mr-4 lg:mr-8 rounded-xl"
                    />
                  </div>
                  <div className="w-[75%]">
                    <h3 className="font-bold mb-2 text-grey text-lg">
                      Launch to the Network
                    </h3>
                    <p>
                      Effortlessly launch your Activity campaigns across a
                      diverse spectrum of community platforms, amplify your
                      reach with ease!
                    </p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="hidden w-full md:w-1/2 lg:flex flex-col items-center justify-center h-full">
              <GatsbyImage
                image={getImage(unlockThePowerImg)}
                alt="Unlock the Power Image"
                className="h-80 w-full rounded-xl shadow-lg"
              />
            </div>
          </div>
        </section>
        <section className="bg-highlight bg-opacity-10 rounded-xl p-6 lg:h-[532px] shadow-md">
          <div className="flex lg:flex-row flex-col justify-between h-full items-center gap-8">
            <div className="w-full lg:w-1/2 p-4 h-full max-w-[448px] mx-auto text-center lg:text-left flex flex-col items-center justify-center">
              <h2 className="text-3xl md:text-4xl font-bold text-default !leading-[normal] mb-4">
                Captivate your Local <br /> Audience with Billboards <br />
                and Social Media!
              </h2>
              <p className=" mb-4">
                Engage your audience through powerful digital billboard and
                social media campaigns, and leverage our extensive community
                network to promote your activities to local customers in your
                area.
              </p>
            </div>
            <div className=" lg:w-1/2">
              <GatsbyImage
                image={getImage(captivateImage)}
                alt="Thankful Party Image"
                className="w-full h-full"
              />
            </div>
          </div>
        </section>
        <div className="py-6"></div>
        {/* Community Section */}
        <section className="bg-secondary-orange bg-opacity-10 rounded-xl p-6 shadow-md">
          <div className="flex lg:flex-row flex-col justify-between h-full items-center gap-8">
            <div className="lg:w-1/2 lg:order-1 order-2">
              <GatsbyImage
                image={getImage(aboardDesktopImage)}
                alt="Thankful Party Image"
                className="w-full h-full"
              />
            </div>
            <div className="w-full lg:w-1/2 p-4 h-full text-center lg:text-left flex flex-col items-center justify-center lg:order-2 order-1 max-w-[448px] mx-auto">
              <h2 className="text-3xl md:text-4xl font-bold text-secondary-orange !leading-[normal] mb-4">
                Build a Stronger Community with <br />
                Real-World Assets
              </h2>
              <p className="mb-4">
                Maximize customer retention by placing QR stickers and A-Frames
                strategically, ensuring locals discover and engage with your
                exciting activities.
              </p>
              <p>
                Dive into community events like festivals and scavenger hunts,
                unlocking a pathway to future services, including enticing
                reward programs for community involvement.
              </p>
            </div>
          </div>
        </section>
        <div className="py-6"></div>
        <section className="bg-secondary-cyan bg-opacity-10 rounded-xl p-6 lg:h-[532px] shadow-md">
          <div className="flex lg:flex-row flex-col justify-between h-full items-center gap-8">
            <div className="w-full lg:w-1/2 p-4 h-full text-center lg:text-left flex flex-col items-center justify-center max-w-[448px] mx-auto">
              <h2 className="text-3xl md:text-4xl font-bold text-secondary-cyan !leading-[normal] mb-4">
                Integrated Maps and Community Influencers
              </h2>
              <p className="mb-4">
                Optimize your business strategy with integrated maps, ensuring
                your activities are seamlessly discovered by your local
                audience.
              </p>
              <p>
                Partner with real-life local influencers to bring their
                communities to your doorstep, fostering a stronger connection
                between your brand and engaged consumers.
              </p>
            </div>
            <div className="lg:w-1/2">
              <GatsbyImage
                image={getImage(integrateImage)}
                alt="Grand Rapids Map Image"
                className="w-full h-full"
              />
            </div>
          </div>
        </section>
        <div className="py-6"></div>
        {/* Real Results Section */}
        <div className="md:p-6 py-12 -mx-4 md:-mx-6 lg:-mx-0">
          <h2 className="text-grey text-center text-3xl font-bold mb-12">
            Real Results from Real Business Owners
          </h2>{' '}
          <div className="px-6 md:px-0 flex flex-col md:flex-row justify-between items-center gap-12">
            <div className="order-2 md:order-1 w-full md:w-1/3 text-center lg:text-left gap-8 flex md:flex-col ">
              <div className="mb-4 flex flex-col justify-start gap-2 items-center lg:items-start pb-4 lg:border-b">
                <p className="text-3xl lg:text-6xl font-bold text-mi-purple">
                  30%
                </p>
                <p className="md:text-xl lg:text-2xl">Increased foot traffic</p>
              </div>

              <div className="mb-4 flex flex-col justify-start gap-2 items-center lg:items-start pb-4 lg:border-b">
                <p className="text-3xl lg:text-6xl font-bold text-secondary-orange">
                  100M+
                </p>
                <p className="md:text-xl lg:text-2xl">
                  Impressions served annually
                </p>
              </div>

              <div className="mb-4 flex flex-col justify-start gap-2 items-center lg:items-start pb-4 lg:border-b">
                <p className="text-3xl lg:text-6xl font-bold text-secondary-blue">
                  95%
                </p>
                <p className="md:text-xl lg:text-2xl">Customers satisfied</p>
              </div>
            </div>

            <div className="lg:w-2/3 order-1 md:order-2 w-full">
              <HorizontalSlider
                className="block md:hidden mb-12"
                whiteDots={false}
              >
                <div className="w-full mb-4 h-[374px] flex flex-col justify-between shadow-md rounded-xl p-4 border-grey border border-opacity-10">
                  <blockquote className="h-1/2 text-grey">
                    <p className="mb-4">
                      Our 3 year anniversary was great! A lot of people
                      discovered us for the first time due to the marketing
                      efforts from Flockx. Being a newer business in town, you
                      need to be creative in order to get the word out there.
                    </p>
                    <p className="mb-4">
                      With Flockx providing the designing and overall strategy
                      including banners, the process couldn't have been easier
                      for me as a business owner. They pushed a ton of traffic
                      to the store, it was a great event and I'm really
                      impressed.
                    </p>
                  </blockquote>
                  <div className="flex justify-between h-1/2 pb-4 items-end mt-4">
                    <div className="flex items-center justify-center h-10 relative">
                      <GatsbyImage
                        image={getImage(basicBeLogoImg)}
                        alt="Rebel Logo"
                        className="w-20"
                      />
                    </div>

                    <cite className="text-right text-highlight">
                      - Renise Warners, Basic Bee
                    </cite>
                  </div>
                </div>

                <div className="w-full mb-4 h-[374px] flex flex-col justify-between shadow-md rounded-xl p-4 border-grey border border-opacity-10">
                  <blockquote className="h-1/2 text-grey">
                    <p className="mb-4">
                      This was our first holiday market ever! Flockx helped us
                      spread the word and notify local communities quickly.
                    </p>
                    <p className="mb-4">
                      Their help with designing ads and putting them up on
                      digital billboards around the city was a great way to
                      build and grow awareness about the event. Which was
                      incredibly successful! We now plan to host this event
                      annually! Thank you Flockx!
                    </p>
                  </blockquote>
                  <div className="flex justify-between h-1/2 items-end pb-4 mt-4">
                    <div className="flex items-center justify-center h-10 relative">
                      <GatsbyImage
                        image={getImage(childrensMuseumLogoImg)}
                        alt="Children's Museum Logo"
                        className="w-20"
                      />
                    </div>

                    <cite className="text-right text-highlight">
                      - Megan Geiken, GRCM
                    </cite>
                  </div>
                </div>
              </HorizontalSlider>
              {/* iPad desktop size */}
              <div className="hidden md:block mb-6 lg:w-[80%] ml-auto shadow-md rounded-xl p-4 border-grey border border-opacity-10">
                <blockquote className=" text-grey">
                  <p className="mb-4">
                    Our 3 year anniversary was great! A lot of people discovered
                    us for the first time due to the marketing efforts from
                    Flockx. Being a newer business in town, you need to be
                    creative in order to get the word out there.
                  </p>
                  <p className="mb-4">
                    With Flockx providing the designing and overall strategy
                    including banners, the process couldn't have been easier for
                    me as a business owner. They pushed a ton of traffic to the
                    store, it was a great event and I'm really impressed.
                  </p>
                </blockquote>
                <div className="flex justify-between items-center mt-4">
                  <div className="flex items-center justify-center h-10 relative">
                    <GatsbyImage
                      image={getImage(basicBeLogoImg)}
                      alt="Rebel Logo"
                      className="w-20"
                    />
                  </div>

                  <cite className="text-right text-highlight">
                    - Renise Warners, Basic Bee
                  </cite>
                </div>
              </div>

              <div className="hidden md:block mb-6 lg:w-[80%] mr-auto shadow-md rounded-xl p-4 border-grey border border-opacity-10">
                <blockquote className=" text-grey">
                  <p className="mb-4">
                    This was our first holiday market ever! Flockx helped us
                    spread the word and notify local communities quickly.
                  </p>
                  <p className="mb-4">
                    Their help with designing ads and putting them up on digital
                    billboards around the city was a great way to build and grow
                    awareness about the event. Which was incredibly successful!
                    We now plan to host this event annually! Thank you Flockx!
                  </p>
                </blockquote>
                <div className="flex justify-between items-center mt-4">
                  <div className="flex items-center justify-center h-10 relative">
                    <GatsbyImage
                      image={getImage(childrensMuseumLogoImg)}
                      alt="Children's Museum Logo"
                      className="w-20"
                    />
                  </div>

                  <cite className="text-right text-highlight">
                    - Megan Geiken, GRCM
                  </cite>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="container mx-auto p-0 py-12">
          <h2 className="text-3xl font-bold text-center mb-8 text-grey">
            Success Stories
          </h2>
          <HorizontalSlider whiteDots={false} className={'block lg:hidden'}>
            <Link
              to="/blog/case-studies/rebel-business"
              className="w-full block"
            >
              <div className="rounded-xl w-full  flex flex-col justify-between min-h-[354px] overflow-hidden shadow-md border-grey border border-opacity-5 mb-4 bg-white">
                <div className="px-6 py-4">
                  <div className="font-bold text-xl mb-2 text-grey">
                    Small Business
                  </div>
                  <p className="text-gray-700 text-base">
                    Rebel Gift Shop, partnered with Flockx to create a buzz for
                    their exclusive event. The challenge to drive awareness and
                    improve sales.
                  </p>
                </div>
                <div className="w-full bg-gray-100 h-44">
                  <GatsbyImage
                    image={getImage(smBusinessImg)}
                    alt="Small Business"
                    className="object-cover w-full h-full"
                  />
                </div>
              </div>
            </Link>
            {/* Event Organizer */}
            <Link
              to="/blog/community/grand-rapids/artprize-flockx-partnership"
              className="w-full block"
            >
              <div className="rounded-xl w-full flex flex-col justify-between min-h-[354px] overflow-hidden shadow-md border-grey border border-opacity-5 mb-4 bg-white">
                <div className="px-6 py-4">
                  <div className="font-bold text-xl mb-2 text-grey">
                    Event Organizer
                  </div>
                  <p className="text-gray-700 text-base">
                    Discover how the Flockx-ArtPrize partnership transformed
                    Grand Rapids, connecting art enthusiasts with local
                    businesses.
                  </p>
                </div>
                <div className="w-full bg-gray-100 h-44">
                  <GatsbyImage
                    image={getImage(artPrizeUsecaseImg)}
                    alt="Event Organizer"
                    className="object-cover w-full h-full"
                  />
                </div>
              </div>
            </Link>
            {/* Neighborhood Associations */}
            <Link
              to="/blog/case-studies/uptown-shophop"
              className="block w-full"
            >
              <div className="rounded-xl w-full flex flex-col justify-between min-h-[354px] overflow-hidden shadow-md border-grey border border-opacity-5 mb-4 bg-white">
                <div className="px-6 py-4">
                  <div className="font-bold text-xl mb-2 text-grey">
                    Neighborhood Associations
                  </div>
                  <p className="text-gray-700 text-base">
                    The Uptown Shop Hop Case Study demonstrates flockx’ ability
                    to garner impressions and drive additional foot traffic to
                    businesses.
                  </p>
                </div>
                <div className="w-full bg-gray-100 h-44">
                  <GatsbyImage
                    image={getImage(neighborhoodUsecaseImg)}
                    alt="Neighborhood Associations"
                    className="object-cover w-full h-full"
                  />
                </div>
              </div>
            </Link>
          </HorizontalSlider>
          {/* Desktop Eye Pad Size */}
          <div className="hidden lg:flex flex-col lg:flex-row mx-auto md:max-w-md lg:max-w-none justify-between gap-4 lg:gap-2 items-center">
            {/* Small Business */}
            <Link
              to="/blog/case-studies/rebel-business"
              className="w-full lg:w-1/3"
            >
              <div className="rounded-xl w-full  flex flex-col justify-between min-h-[354px] overflow-hidden shadow-md border-grey border border-opacity-5 m-4 bg-white">
                <div className="px-6 py-4">
                  <div className="font-bold text-xl mb-2 text-grey">
                    Small Business
                  </div>
                  <p className="text-gray-700 text-base">
                    Rebel Gift Shop, partnered with Flockx to create a buzz for
                    their exclusive event. The challenge to drive awareness and
                    improve sales.
                  </p>
                </div>
                <div className="w-full bg-gray-100 h-44">
                  <GatsbyImage
                    image={getImage(smBusinessImg)}
                    alt="Small Business"
                    className="object-cover w-full h-full"
                  />
                </div>
              </div>
            </Link>
            {/* Event Organizer */}
            <Link
              to="/blog/community/grand-rapids/artprize-flockx-partnership"
              className="w-full lg:w-1/3"
            >
              <div className="rounded-xl w-full flex flex-col justify-between min-h-[354px] overflow-hidden shadow-md border-grey border border-opacity-5 m-4 bg-white">
                <div className="px-6 py-4">
                  <div className="font-bold text-xl mb-2 text-grey">
                    Event Organizer
                  </div>
                  <p className="text-gray-700 text-base">
                    Discover how the Flockx-ArtPrize partnership transformed
                    Grand Rapids, connecting art enthusiasts with local
                    businesses.
                  </p>
                </div>
                <div className="w-full bg-gray-100 h-44">
                  <GatsbyImage
                    image={getImage(artPrizeUsecaseImg)}
                    alt="Event Organizer"
                    className="object-cover w-full h-full"
                  />
                </div>
              </div>
            </Link>
            {/* Neighborhood Associations */}
            <Link
              to="/blog/case-studies/uptown-shophop"
              className="w-full lg:w-1/3"
            >
              <div className="rounded-xl w-full flex flex-col justify-between min-h-[354px] overflow-hidden shadow-md border-grey border border-opacity-5 m-4 bg-white">
                <div className="px-6 py-4">
                  <div className="font-bold text-xl mb-2 text-grey">
                    Neighborhood Associations
                  </div>
                  <p className="text-gray-700 text-base">
                    The Uptown Shop Hop Case Study demonstrates flockx’ ability
                    to garner impressions and drive additional foot traffic to
                    businesses.
                  </p>
                </div>
                <div className="w-full bg-gray-100 h-44">
                  <GatsbyImage
                    image={getImage(neighborhoodUsecaseImg)}
                    alt="Neighborhood Associations"
                    className="object-cover w-full h-full"
                  />
                </div>
              </div>
            </Link>
          </div>
        </section>
        {/* Call to Action Section */}
        <section className="bg-highlight bg-opacity-10 p-2 lg:p-6 rounded-lg shadow-md mx-auto max-w-4xl">
          <div className="flex flex-col md:flex-row justify-between text-center md:text-left lg:gap-8 items-center">
            <div className="md:w-1/2 px-12 pt-8 md:py-8">
              <h2 className="text-3xl font-bold text-default mb-4">
                Get Started for Free!
              </h2>
              <p className="mb-4 md:mb-8 text-grey">
                Leverage our extensive community network to promote your
                activities to local customers in your area.
              </p>
              <Button
                label="Get Started Today"
                onClickHandler={handleDemoSignUp}
                className={'hidden md:inline'}
              />
            </div>
            <div className="md:w-1/2 px-4 py-8 md:p-8 md:px-12">
              <h3 className="text-xl font-semibold mb-3 text-grey">
                What's included
              </h3>
              <ul className="list-none">
                <li className="flex items-center mb-2">
                  <span className="bg-white rounded-full h-6 w-6 flex items-center justify-center mr-4">
                    <span className="text-purple-600 text-sm">✔</span>
                  </span>
                  Unlimited Activity Detection
                </li>
                <li className="flex items-center mb-2">
                  <span className="bg-white rounded-full h-6 w-6 flex items-center justify-center mr-4">
                    <span className="text-purple-600 text-sm">✔</span>
                  </span>
                  Local Activity Planner
                </li>
                <li className="flex items-center mb-2">
                  <span className="bg-white rounded-full h-6 w-6 flex items-center justify-center mr-4">
                    <span className="text-purple-600 text-sm">✔</span>
                  </span>
                  Publish to the Community Network
                </li>
                <li className="flex items-center mb-2">
                  <span className="bg-white rounded-full h-6 w-6 flex items-center justify-center mr-4">
                    <span className="text-purple-600 text-sm">✔</span>
                  </span>
                  Email Support
                </li>
                <li className="flex items-center">
                  <span className="bg-white rounded-full h-6 w-6 flex items-center justify-center mr-4">
                    <span className="text-purple-600 text-sm">✔</span>
                  </span>
                  In App Add-Ons
                </li>
              </ul>
              <Button
                label="Get Started Today"
                onClickHandler={handleDemoSignUp}
                className={'block md:hidden mt-10 text-xl '}
              />
            </div>
          </div>
        </section>
        <div className="py-12"></div>
        <BusinessApplicant />
        <div className="py-12"></div>{' '}
      </div>
    </BaseLayout>
  );
};

export const query = graphql`
  query BusinessPageV2Query {
    aiIconImg: file(name: { eq: "ai-icon" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    artPrizeUsecaseImg: file(name: { eq: "artprize-usecase" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    basicBeLogoImg: file(name: { eq: "basic-be-logo" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    brickyardLogoImg: file(name: { eq: "brickyard-logo" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    childrensMuseumLogoImg: file(name: { eq: "children's-museum-logo" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    cityFlatsHotelLogoImg: file(name: { eq: "city-flats-hotel-logo" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    feetIconImg: file(name: { eq: "feet-icon" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    flockxTouchImg: file(name: { eq: "flockx-touch" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    graamaLogoImg: file(name: { eq: "graama-logo" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    launchIconImg: file(name: { eq: "launch-icon" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    linkIconImg: file(name: { eq: "link-icon-2" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    neighborhoodUsecaseImg: file(name: { eq: "neighborhood-usecase" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    rebelLogoImg: file(name: { eq: "rebel-logo" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    smBusinessImg: file(name: { eq: "sm-business" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    timeResourcesIconImg: file(name: { eq: "timeresources-icon" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    unlockThePowerImg: file(name: { eq: "unlock-the-power" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    captivateImage: file(name: { eq: "captivate-audience-images" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    integrateImage: file(name: { eq: "integrated-maps-images" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    aboardDesktopImage: file(name: { eq: "qr-code-examples-desktop" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

export default BusinessPage;
